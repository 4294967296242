<template>
  <div>
      <div class="body">
          <div class="body-logo">
              <div>
                  <img src="../../public/role4.png">
              </div>
          </div>
          <div class="body-title">
              <div>
                  创建您的虚拟角色
              </div>
          </div>
          <div class="body-input">
              <div>
                  <input v-model="iptVal" type="text" placeholder="请输入您的昵称" />
              </div>
          </div>
          <div class="body-button">
              <div @click="confirm">
                  <div> 确认</div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name:"role",
  data() {
    return {
      iptVal: "",
    };
  },
  methods:{
      confirm(){
          window.game.gameMode.localPlayer.setName(this.iptVal)
          this.$emit('changeStatus');
      }
  },
  created(){
      document.documentElement.style.fontSize = document.documentElement.clientWidth / 750 * 100 + 'px';
  }
};
</script>

<style>
@media screen and (max-width: 768px) {
    .body{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("../../public/role1.png");
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;
    }
    .body > .body-logo{
        width: 100%;
        height: 2rem;
        margin-top: 2rem;
        display: flex;
        justify-content: center;
    }
    .body > .body-logo > div{
        height: 2rem;
        width: 4.4rem;
    }
    .body > .body-logo > div > img{
        height: 2rem;
        width: 4.4rem;
    }
    .body > .body-title{
        margin-top: 1rem;
        width: 100%;
        height: 0.3rem;
        color: white;
        font-size: 0.3rem;
        display: flex;
        justify-content: center;
    }
    .body > .body-title > div{
        height: 0.3rem;
        width: 6.6rem;
        margin-left: 0.7rem;
    }

    .body > .body-input {
        width: 100%;
        height: 1.69rem;
        display: flex;
        justify-content: center;
    }
    .body > .body-input > div{
        width: 6.60rem;
        height: 1.7rem;
        background-image: url("../../public/role8.png");    
        display: flex;
        align-items: center;
    }
    .body > .body-input > div > input{
        width: 6.6rem;
        height: 1.7rem;
        border: none;
        font-size: 0.32rem;
        padding-left: 0.6rem;
        outline: none;
        color: white;
    }
    input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: white;
    font-size: 3.7333vw;
    }
    input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: white;
    font-size: 3.7333vw;
    }
    input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: white;
    font-size: 3.7333vw;
    }
    input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: white;
    font-size: 3.7333vw;
    }
    .body > .body-button{
        width: 100%;
        height: 1.4rem;
        display: flex;
        justify-content: center;
    }
    .body > .body-button > div{
        background-image: url("../../public/role9.png");
        width: 3rem;
        height: 1.4rem;
        cursor: pointer;
    }
    .body > .body-button > div > div{
        color: white;
        font-size: 0.28rem;
        line-height: 1.4rem;
        text-align: center;
    }
}
@media screen and (min-width: 768px) {
    .body{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("../../public/role5.png");
        background-size: cover;
        background-repeat: no-repeat;
    }
    .body > .body-logo{
        width: 100%;
        height: 200px;
    }
    .body > .body-logo > div{
        width: 442px;
        height: 200px;
        margin: 0 auto;
        margin-top: 100px;
    }
    .body > .body-logo > div > img{
        width: 442px;
        height: 200px;
    }
    .body > .body-title {
        margin-top: 100px;
        width: 100%;
        height: 23px;
    }
    .body > .body-title > div{
        width: 660px;
        height: 23px;
        font-size: 18px;
        color: white;
        padding-left: 28px;
        margin: 0 auto;
    }
    .body > .body-input {
        width: 100%;
        height: 170px;
        display: flex;
        justify-content: center;
    }
    .body > .body-input > div{
        width: 660px;
        height: 170px;
        background-image: url("../../public/role2.png");
        display: flex;
    }
    .body > .body-input > div > input{
        width: 660px;
        height: 170px;
        border: none;
        font-size: 32px;
        padding-left: 60px;
        color: white;
          outline: none;
    }
    input::-webkit-input-placeholder {
        /* WebKit browsers */
        color: white;
        font-size: 28px;
    }
    input:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: white;
        font-size: 28px;
    }
    input::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: white;
        font-size: 28px;
    }
    input:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: white;
        font-size: 28px;
    }
    .body > .body-button{
        width: 100%;
        height: 140px;
        margin-top: 36px;
        display: flex;
        justify-content: center;
    }
    .body > .body-button > div{
        background-image: url("../../public/role7.png");
        width: 300px;
        height: 140px;
        cursor: pointer;
    }
    .body > .body-button > div > div{
        color: white;
        font-size: 28px;
        line-height: 140px;
        text-align: center;
    }
}
    
</style>
